import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import { pathOr } from 'ramda'

import API from 'api'
import { Search, Footer } from 'components'
import { getDiamondAction } from 'store/actions'
import { selectDiamond } from 'store/selectors'

import Highlights from './Highlights'
import Avatar from './Avatar'
import TabSelector from './TabSelector'
import Tab from './Tab'
import classes from './styles.module.scss'

export const DiamondPage = () => {
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const diamond = useSelector(selectDiamond)
  const [tab, setTab] = useState()
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [headerImage, setHeaderImage] = useState()

  const handleSearchSubmit = value => {
    dispatch(getDiamondAction(value))
      .then(data => {
        if (!data) navigate(`/diamond/not-found`)
        else navigate(`/diamond/${value}`)
      })
      .catch(() => {
        navigate(`/diamond/not-found`)
      })
  }

  const handleTabChangeStart = index => {
    setActiveTabIndex(index)
  }

  const handleTabChangeComplete = () => {
    setTab(diamond.supplementary_tags.tabs[activeTabIndex])
  }

  // update tab on diamond change
  useEffect(() => {
    if (!diamond) return

    setActiveTabIndex(0)
    setTab(diamond.supplementary_tags.tabs[0])
  }, [JSON.stringify(diamond)]) // eslint-disable-line react-hooks/exhaustive-deps

  // Update header background image on tab change
  useEffect(() => {
    const hash = pathOr(null, ['header_image', 'hash'], tab)

    if (!diamond || !hash) return

    API.getSignedUrl(diamond.id, hash)
      .then(data => {
        setHeaderImage(data.content)
      })
      .catch(() => {
        setHeaderImage('/images/backgrounds/diamond_header_placeholder.png')
      })
  }, [JSON.stringify(tab)]) // eslint-disable-line react-hooks/exhaustive-deps

  // load the diamond if page refresh
  useEffect(() => {
    if (!diamond && params.query) dispatch(getDiamondAction(params.query))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // load the diamond on click back
  useEffect(() => {
    if (diamond && params.query) dispatch(getDiamondAction(params.query))
  }, [params.query]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!tab) return null

  const isActive = diamond ? diamond.supplementary_tags.tabs.indexOf(tab) === activeTabIndex : false

  return (
    <div className={classes.diamondPageContainer}>
      <header style={{ backgroundImage: `url(${headerImage})` }}>
        <div className={classes.overlay}></div>
        <div className={classes.wrapper}>
          <div className={classes.logo}>
            <a href="/">
              <img src={'/images/logos/gemfair_white.svg'} alt="Gemfair logo" />
            </a>
          </div>
          <div className={classes.search}>
            <Search value={params.query || ''} theme="dark" onSubmit={handleSearchSubmit} />
          </div>
          <h1 className={classnames(classes.heading, { [classes.hide]: !isActive })}>{tab.top_header_text}</h1>
          <div className={classes.highlights}>
            <Highlights items={tab.highlights} isActive={isActive} />
          </div>
          <div className={classes.avatar}>
            {tab.hero_image ? <Avatar id={diamond.id} hash={tab.hero_image.hash} /> : null}
          </div>
        </div>
      </header>
      <div className={classes.tabSelector}>
        <TabSelector
          tabs={diamond.supplementary_tags.tabs}
          activeIndex={activeTabIndex}
          onChange={handleTabChangeStart}
        />
      </div>
      <div className={classes.tab}>
        <Tab tab={tab} diamond={diamond} isActive={isActive} onTransitionComplete={handleTabChangeComplete} />
      </div>
      <Footer />
    </div>
  )
}

export default DiamondPage
